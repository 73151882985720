import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { navigate } from "gatsby";

import Spinner from "../../images/spinner.svg";
import {
  Container,
  ContentContainer,
  AddressContainer,
  AddressInfoContainer,
  AddressInfo,
  AddressName,
  AddressRemoveButtonContainer,
  AddAddressButton,
  DeleteContainer,
  SpinerContainer,
  // Title,
} from "./style";
import TrashIcon from "../../images/trash-red.svg";

import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";

import { AppState } from "../../store";
import { ApiAddress } from "../../types/address";

import { getAddresses, deleteAddress } from "../../store/user/actions";
import { getAddressTypes } from "../confirm-address-modal";
import { Title, TitleContainer } from "../orders/style/orders";
import { BackBtnIcon } from "../profile-back-btn-icon";
import userLanguage from "../hooks/useLanguage";

const AddressLine: FC<ApiAddress> = ({
  id,
  type,
  number,
  street,
  city,
  notes,
}) => {
  const { showToast } = useToast();
  const dispatch = useThunkDispatch();
  const [loading, setLoading] = useState(false);

  const handleDeleteAddress = useCallback(async () => {
    setLoading(true);

    const response = await dispatch(deleteAddress(id));
    if (response && !response.ok) {
      showToast(response.error ? response.error.message : "Error", {
        type: TOAST_TYPE.ERROR,
      });
    }

    setLoading(false);
  }, [dispatch, showToast, id]);

  const locale = userLanguage();
  const addressTypes = useMemo(() => getAddressTypes(locale), [locale]);

  return (
    <AddressContainer>
      <AddressInfoContainer>
        <AddressName>{addressTypes[type]}</AddressName>
        <AddressInfo>{`${number} ${street} ${city}`}</AddressInfo>
        <AddressInfo>{`${notes}`}</AddressInfo>
      </AddressInfoContainer>
      <AddressRemoveButtonContainer>
        {loading ? (
          <Spinner />
        ) : (
          <DeleteContainer onClick={handleDeleteAddress}>
            <TrashIcon />
          </DeleteContainer>
        )}
      </AddressRemoveButtonContainer>
    </AddressContainer>
  );
};

const SavedAddressesScreen: FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const savedAddresses = useSelector<AppState, ApiAddress[]>(
    state => state.user.addresses
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      const response = await dispatch(getAddresses());
      if (response && !response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR,
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, showToast]);

  const goToSelectAddress = useCallback(() => {
    navigate("/add-address");
  }, [navigate]);

  // if (loading) {
  //   return (
  //     <Container>
  //       <Spinner />
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <ContentContainer>
        <TitleContainer>
          <BackBtnIcon />
          <Title>
            <FormattedMessage id="addresses.title" />
          </Title>
        </TitleContainer>
        
        {loading ? <SpinerContainer><Spinner /></SpinerContainer> : null}

        {savedAddresses &&
          savedAddresses.map(address => (
            <AddressLine key={address.id} {...address} />
          ))}
        <AddAddressButton onClick={goToSelectAddress}>
          <FormattedMessage id="address.add" />
        </AddAddressButton>
      </ContentContainer>
    </Container>
  );
};

export default SavedAddressesScreen;
