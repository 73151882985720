import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SavedAddresses from "../components/saved-addresses";

const IndexPage: FC = () => (
  <Layout header menuState="profile" requireAuth>
    <SEO title="Mes adresses" />
    <SavedAddresses />
  </Layout>
);

export default IndexPage;
